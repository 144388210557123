import { ReactElement } from "react";
import { getInitialPropsSlug, Context, DEFAULT_TEMPLATE } from "src/common";
import { BrandAreaGlobalStyle } from "src/common/brand-area/constantsOverwrites/globalstyles";
import Custom404 from "src/common/components/404";
import { CustomHtmlHandler } from "../common/components/CustomHtmlHandler/CustomHtmlHandler";
import dynamic from "next/dynamic";

import { PageT1 } from "src/templates/t1/components/pages/Page";
import { PageBrandT1 } from "src/templates/t1/components/pages/PageBrand";
import { PageBrandOverviewT1 } from "src/templates/t1/components/pages/PageBrandOverview";
import { PageBrandTopicT1 } from "src/templates/t1/components/pages/PageBrandTopic";
import { PageContactT1 } from "src/templates/t1/components/pages/PageContact";
import { PageDateInquiryT1 } from "src/templates/t1/components/pages/PageDateInquiry";
import { PageGuestbookT1 } from "src/templates/t1/components/pages/PageGuestbook";
import { PageImprintT1 } from "src/templates/t1/components/pages/PageImprint";
import { PageMicroT1 } from "src/templates/t1/components/pages/PageMicro";
import { PageNewsT1 } from "src/templates/t1/components/pages/PageNews";
import { PageRepairInquiryT1 } from "src/templates/t1/components/pages/PageRepairInquiry";
const PageT10 = dynamic<any>(() => import("src/templates/t10/components/pages/Page").then(m => m.PageT10));
const PageBrandT10 = dynamic<any>(() =>
    import("src/templates/t10/components/pages/PageBrand").then(m => m.PageBrandT10)
);
const PageBrandOverviewT10 = dynamic<any>(() =>
    import("src/templates/t10/components/pages/PageBrandOverview").then(m => m.PageBrandOverviewT10)
);
const PageContactT10 = dynamic<any>(() =>
    import("src/templates/t10/components/pages/PageContact").then(m => m.PageContactT10)
);
const PageCourseT10 = dynamic<any>(() =>
    import("src/templates/t10/components/pages/PageCourse").then(m => m.PageCourseT10)
);
const PageDateInquiryT10 = dynamic<any>(() =>
    import("src/templates/t10/components/pages/PageDateInquiry").then(m => m.PageDateInquiryT10)
);
const PageGuestbookT10 = dynamic<any>(() =>
    import("src/templates/t10/components/pages/PageGuestbook").then(m => m.PageGuestbookT10)
);
const PageImprintT10 = dynamic<any>(() =>
    import("src/templates/t10/components/pages/PageImprint").then(m => m.PageImprintT10)
);
const PageNewsT10 = dynamic<any>(() =>
    import("src/templates/t10/components/pages/PageNews").then(m => m.PageNewsT10)
);
const PageRepairInquiryT10 = dynamic<any>(() =>
    import("src/templates/t10/components/pages/PageRepairInquiry").then(m => m.PageRepairInquiryT10)
);
const PageT11 = dynamic<any>(() => import("src/templates/t11/components/pages/Page").then(m => m.PageT11));
const PageBrandT11 = dynamic<any>(() =>
    import("src/templates/t11/components/pages/PageBrand").then(m => m.PageBrandT11)
);
const PageBrandOverviewT11 = dynamic<any>(() =>
    import("src/templates/t11/components/pages/PageBrandOverview").then(m => m.PageBrandOverviewT11)
);
const PageBrandTopicT11 = dynamic<any>(() =>
    import("src/templates/t11/components/pages/PageBrandTopic").then(m => m.PageBrandTopicT11)
);
const PageContactT11 = dynamic<any>(() =>
    import("src/templates/t11/components/pages/PageContact").then(m => m.PageContactT11)
);
const PageDateInquiryT11 = dynamic<any>(() =>
    import("src/templates/t11/components/pages/PageDateInquiry").then(m => m.PageDateInquiryT11)
);
const PageGuestbookT11 = dynamic<any>(() =>
    import("src/templates/t11/components/pages/PageGuestbook").then(m => m.PageGuestbookT11)
);
const PageImprintT11 = dynamic<any>(() =>
    import("src/templates/t11/components/pages/PageImprint").then(m => m.PageImprintT11)
);
const PageNewsT11 = dynamic<any>(() =>
    import("src/templates/t11/components/pages/PageNews").then(m => m.PageNewsT11)
);
const PageRepairInquiryT11 = dynamic<any>(() =>
    import("src/templates/t11/components/pages/PageRepairInquiry").then(m => m.PageRepairInquiryT11)
);
const PageT12 = dynamic<any>(() => import("src/templates/t12/components/pages/Page").then(m => m.PageT12));
const PageBrandT12 = dynamic<any>(() =>
    import("src/templates/t12/components/pages/PageBrand").then(m => m.PageBrandT12)
);
const PageBrandOverviewT12 = dynamic<any>(() =>
    import("src/templates/t12/components/pages/PageBrandOverview").then(m => m.PageBrandOverviewT12)
);
const PageContactT12 = dynamic<any>(() =>
    import("src/templates/t12/components/pages/PageContact").then(m => m.PageContactT12)
);
const PageDateInquiryT12 = dynamic<any>(() =>
    import("src/templates/t12/components/pages/PageDateInquiry").then(m => m.PageDateInquiryT12)
);
const PageGuestbookT12 = dynamic<any>(() =>
    import("src/templates/t12/components/pages/PageGuestbook").then(m => m.PageGuestbookT12)
);
const PageImprintT12 = dynamic<any>(() =>
    import("src/templates/t12/components/pages/PageImprint").then(m => m.PageImprintT12)
);
const PageNewsT12 = dynamic<any>(() =>
    import("src/templates/t12/components/pages/PageNews").then(m => m.PageNewsT12)
);
const PageRepairInquiryT12 = dynamic<any>(() =>
    import("src/templates/t12/components/pages/PageRepairInquiry").then(m => m.PageRepairInquiryT12)
);
const PageT13 = dynamic<any>(() => import("src/templates/t13/components/pages/Page").then(m => m.PageT13));
const PageBrandT13 = dynamic<any>(() =>
    import("src/templates/t13/components/pages/PageBrand").then(m => m.PageBrandT13)
);
const PageBrandOverviewT13 = dynamic<any>(() =>
    import("src/templates/t13/components/pages/PageBrandOverview").then(m => m.PageBrandOverviewT13)
);
const PageContactT13 = dynamic<any>(() =>
    import("src/templates/t13/components/pages/PageContact").then(m => m.PageContactT13)
);
const PageCourseT13 = dynamic<any>(() =>
    import("src/templates/t13/components/pages/PageCourse").then(m => m.PageCourseT13)
);
const PageDateInquiryT13 = dynamic<any>(() =>
    import("src/templates/t13/components/pages/PageDateInquiry").then(m => m.PageDateInquiryT13)
);
const PageGuestbookT13 = dynamic<any>(() =>
    import("src/templates/t13/components/pages/PageGuestbook").then(m => m.PageGuestbookT13)
);
const PageImprintT13 = dynamic<any>(() =>
    import("src/templates/t13/components/pages/PageImprint").then(m => m.PageImprintT13)
);
const PageNewsT13 = dynamic<any>(() =>
    import("src/templates/t13/components/pages/PageNews").then(m => m.PageNewsT13)
);
const PageRepairInquiryT13 = dynamic<any>(() =>
    import("src/templates/t13/components/pages/PageRepairInquiry").then(m => m.PageRepairInquiryT13)
);
const PageT14 = dynamic<any>(() => import("src/templates/t14/components/pages/Page").then(m => m.PageT14));
const PageBrandT14 = dynamic<any>(() =>
    import("src/templates/t14/components/pages/PageBrand").then(m => m.PageBrandT14)
);
const PageBrandOverviewT14 = dynamic<any>(() =>
    import("src/templates/t14/components/pages/PageBrandOverview").then(m => m.PageBrandOverviewT14)
);
const PageContactT14 = dynamic<any>(() =>
    import("src/templates/t14/components/pages/PageContact").then(m => m.PageContactT14)
);
const PageDateInquiryT14 = dynamic<any>(() =>
    import("src/templates/t14/components/pages/PageDateInquiry").then(m => m.PageDateInquiryT14)
);
const PageGuestbookT14 = dynamic<any>(() =>
    import("src/templates/t14/components/pages/PageGuestbook").then(m => m.PageGuestbookT14)
);
const PageImprintT14 = dynamic<any>(() =>
    import("src/templates/t14/components/pages/PageImprint").then(m => m.PageImprintT14)
);
const PageNewsT14 = dynamic<any>(() =>
    import("src/templates/t14/components/pages/PageNews").then(m => m.PageNewsT14)
);
const PageRepairInquiryT14 = dynamic<any>(() =>
    import("src/templates/t14/components/pages/PageRepairInquiry").then(m => m.PageRepairInquiryT14)
);
const PageT15 = dynamic<any>(() => import("src/templates/t15/components/pages/Page").then(m => m.PageT15));
const PageBrandT15 = dynamic<any>(() =>
    import("src/templates/t15/components/pages/PageBrand").then(m => m.PageBrandT15)
);
const PageBrandOverviewT15 = dynamic<any>(() =>
    import("src/templates/t15/components/pages/PageBrandOverview").then(m => m.PageBrandOverviewT15)
);
const PageContactT15 = dynamic<any>(() =>
    import("src/templates/t15/components/pages/PageContact").then(m => m.PageContactT15)
);
const PageDateInquiryT15 = dynamic<any>(() =>
    import("src/templates/t15/components/pages/PageDateInquiry").then(m => m.PageDateInquiryT15)
);
const PageGuestbookT15 = dynamic<any>(() =>
    import("src/templates/t15/components/pages/PageGuestbook").then(m => m.PageGuestbookT15)
);
const PageImprintT15 = dynamic<any>(() =>
    import("src/templates/t15/components/pages/PageImprint").then(m => m.PageImprintT15)
);
const PageNewsT15 = dynamic<any>(() =>
    import("src/templates/t15/components/pages/PageNews").then(m => m.PageNewsT15)
);
const PageRepairInquiryT15 = dynamic<any>(() =>
    import("src/templates/t15/components/pages/PageRepairInquiry").then(m => m.PageRepairInquiryT15)
);
const PageT16 = dynamic<any>(() => import("src/templates/t16/components/pages/Page").then(m => m.PageT16));
const PageBrandT16 = dynamic<any>(() =>
    import("src/templates/t16/components/pages/PageBrand").then(m => m.PageBrandT16)
);
const PageBrandOverviewT16 = dynamic<any>(() =>
    import("src/templates/t16/components/pages/PageBrandOverview").then(m => m.PageBrandOverviewT16)
);
const PageContactT16 = dynamic<any>(() =>
    import("src/templates/t16/components/pages/PageContact").then(m => m.PageContactT16)
);
const PageCourseT16 = dynamic<any>(() =>
    import("src/templates/t16/components/pages/PageCourse").then(m => m.PageCourseT16)
);
const PageDateInquiryT16 = dynamic<any>(() =>
    import("src/templates/t16/components/pages/PageDateInquiry").then(m => m.PageDateInquiryT16)
);
const PageGuestbookT16 = dynamic<any>(() =>
    import("src/templates/t16/components/pages/PageGuestbook").then(m => m.PageGuestbookT16)
);
const PageImprintT16 = dynamic<any>(() =>
    import("src/templates/t16/components/pages/PageImprint").then(m => m.PageImprintT16)
);
const PageNewsT16 = dynamic<any>(() =>
    import("src/templates/t16/components/pages/PageNews").then(m => m.PageNewsT16)
);
const PageRepairInquiryT16 = dynamic<any>(() =>
    import("src/templates/t16/components/pages/PageRepairInquiry").then(m => m.PageRepairInquiryT16)
);
const PageT17 = dynamic<any>(() => import("src/templates/t17/components/pages/Page").then(m => m.PageT17));
const PageBrandT17 = dynamic<any>(() =>
    import("src/templates/t17/components/pages/PageBrand").then(m => m.PageBrandT17)
);
const PageBrandOverviewT17 = dynamic<any>(() =>
    import("src/templates/t17/components/pages/PageBrandOverview").then(m => m.PageBrandOverviewT17)
);
const PageContactT17 = dynamic<any>(() =>
    import("src/templates/t17/components/pages/PageContact").then(m => m.PageContactT17)
);
const PageDateInquiryT17 = dynamic<any>(() =>
    import("src/templates/t17/components/pages/PageDateInquiry").then(m => m.PageDateInquiryT17)
);
const PageGuestbookT17 = dynamic<any>(() =>
    import("src/templates/t17/components/pages/PageGuestbook").then(m => m.PageGuestbookT17)
);
const PageImprintT17 = dynamic<any>(() =>
    import("src/templates/t17/components/pages/PageImprint").then(m => m.PageImprintT17)
);
const PageNewsT17 = dynamic<any>(() =>
    import("src/templates/t17/components/pages/PageNews").then(m => m.PageNewsT17)
);
const PageRepairInquiryT17 = dynamic<any>(() =>
    import("src/templates/t17/components/pages/PageRepairInquiry").then(m => m.PageRepairInquiryT17)
);
const PageT18 = dynamic<any>(() => import("src/templates/t18/components/pages/Page").then(m => m.PageT18));
const PageBrandT18 = dynamic<any>(() =>
    import("src/templates/t18/components/pages/PageBrand").then(m => m.PageBrandT18)
);
const PageBrandOverviewT18 = dynamic<any>(() =>
    import("src/templates/t18/components/pages/PageBrandOverview").then(m => m.PageBrandOverviewT18)
);
const PageContactT18 = dynamic<any>(() =>
    import("src/templates/t18/components/pages/PageContact").then(m => m.PageContactT18)
);
const PageCourseT18 = dynamic<any>(() =>
    import("src/templates/t18/components/pages/PageCourse").then(m => m.PageCourseT18)
);
const PageDateInquiryT18 = dynamic<any>(() =>
    import("src/templates/t18/components/pages/PageDateInquiry").then(m => m.PageDateInquiryT18)
);
const PageGuestbookT18 = dynamic<any>(() =>
    import("src/templates/t18/components/pages/PageGuestbook").then(m => m.PageGuestbookT18)
);
const PageImprintT18 = dynamic<any>(() =>
    import("src/templates/t18/components/pages/PageImprint").then(m => m.PageImprintT18)
);
const PageMicroT18 = dynamic<any>(() =>
    import("src/templates/t18/components/pages/PageMicro").then(m => m.PageMicroT18)
);
const PageNewsT18 = dynamic<any>(() =>
    import("src/templates/t18/components/pages/PageNews").then(m => m.PageNewsT18)
);
const PageRepairInquiryT18 = dynamic<any>(() =>
    import("src/templates/t18/components/pages/PageRepairInquiry").then(m => m.PageRepairInquiryT18)
);
const PageT19 = dynamic<any>(() => import("src/templates/t19/components/pages/Page").then(m => m.PageT19));
const PageBrandT19 = dynamic<any>(() =>
    import("src/templates/t19/components/pages/PageBrand").then(m => m.PageBrandT19)
);
const PageBrandOverviewT19 = dynamic<any>(() =>
    import("src/templates/t19/components/pages/PageBrandOverview").then(m => m.PageBrandOverviewT19)
);
const PageContactT19 = dynamic<any>(() =>
    import("src/templates/t19/components/pages/PageContact").then(m => m.PageContactT19)
);
const PageDateInquiryT19 = dynamic<any>(() =>
    import("src/templates/t19/components/pages/PageDateInquiry").then(m => m.PageDateInquiryT19)
);
const PageGuestbookT19 = dynamic<any>(() =>
    import("src/templates/t19/components/pages/PageGuestbook").then(m => m.PageGuestbookT19)
);
const PageImprintT19 = dynamic<any>(() =>
    import("src/templates/t19/components/pages/PageImprint").then(m => m.PageImprintT19)
);
const PageMicroT19 = dynamic<any>(() =>
    import("src/templates/t19/components/pages/PageMicro").then(m => m.PageMicroT19)
);
const PageNewsT19 = dynamic<any>(() =>
    import("src/templates/t19/components/pages/PageNews").then(m => m.PageNewsT19)
);
const PageRepairInquiryT19 = dynamic<any>(() =>
    import("src/templates/t19/components/pages/PageRepairInquiry").then(m => m.PageRepairInquiryT19)
);
const PageT20 = dynamic<any>(() => import("src/templates/t20/components/pages/Page").then(m => m.PageT20));
const PageBrandT20 = dynamic<any>(() =>
    import("src/templates/t20/components/pages/PageBrand").then(m => m.PageBrandT20)
);
const PageBrandOverviewT20 = dynamic<any>(() =>
    import("src/templates/t20/components/pages/PageBrandOverview").then(m => m.PageBrandOverviewT20)
);
const PageContactT20 = dynamic<any>(() =>
    import("src/templates/t20/components/pages/PageContact").then(m => m.PageContactT20)
);
const PageCourseT20 = dynamic<any>(() =>
    import("src/templates/t20/components/pages/PageCourse").then(m => m.PageCourseT20)
);
const PageDateInquiryT20 = dynamic<any>(() =>
    import("src/templates/t20/components/pages/PageDateInquiry").then(m => m.PageDateInquiryT20)
);
const PageGuestbookT20 = dynamic<any>(() =>
    import("src/templates/t20/components/pages/PageGuestbook").then(m => m.PageGuestbookT20)
);
const PageImprintT20 = dynamic<any>(() =>
    import("src/templates/t20/components/pages/PageImprint").then(m => m.PageImprintT20)
);
const PageNewsT20 = dynamic<any>(() =>
    import("src/templates/t20/components/pages/PageNews").then(m => m.PageNewsT20)
);
const PageRepairInquiryT20 = dynamic<any>(() =>
    import("src/templates/t20/components/pages/PageRepairInquiry").then(m => m.PageRepairInquiryT20)
);
const PageT21 = dynamic<any>(() => import("src/templates/t21/components/pages/Page").then(m => m.PageT21));
const PageBrandT21 = dynamic<any>(() =>
    import("src/templates/t21/components/pages/PageBrand").then(m => m.PageBrandT21)
);
const PageBrandOverviewT21 = dynamic<any>(() =>
    import("src/templates/t21/components/pages/PageBrandOverview").then(m => m.PageBrandOverviewT21)
);
const PageContactT21 = dynamic<any>(() =>
    import("src/templates/t21/components/pages/PageContact").then(m => m.PageContactT21)
);
const PageDateInquiryT21 = dynamic<any>(() =>
    import("src/templates/t21/components/pages/PageDateInquiry").then(m => m.PageDateInquiryT21)
);
const PageGuestbookT21 = dynamic<any>(() =>
    import("src/templates/t21/components/pages/PageGuestbook").then(m => m.PageGuestbookT21)
);
const PageImprintT21 = dynamic<any>(() =>
    import("src/templates/t21/components/pages/PageImprint").then(m => m.PageImprintT21)
);
const PageNewsT21 = dynamic<any>(() =>
    import("src/templates/t21/components/pages/PageNews").then(m => m.PageNewsT21)
);
const PageRepairInquiryT21 = dynamic<any>(() =>
    import("src/templates/t21/components/pages/PageRepairInquiry").then(m => m.PageRepairInquiryT21)
);
const PageT22 = dynamic<any>(() => import("src/templates/t22/components/pages/Page").then(m => m.PageT22));
const PageBrandT22 = dynamic<any>(() =>
    import("src/templates/t22/components/pages/PageBrand").then(m => m.PageBrandT22)
);
const PageBrandOverviewT22 = dynamic<any>(() =>
    import("src/templates/t22/components/pages/PageBrandOverview").then(m => m.PageBrandOverviewT22)
);
const PageContactT22 = dynamic<any>(() =>
    import("src/templates/t22/components/pages/PageContact").then(m => m.PageContactT22)
);
const PageCourseT22 = dynamic<any>(() =>
    import("src/templates/t22/components/pages/PageCourse").then(m => m.PageCourseT22)
);
const PageDateInquiryT22 = dynamic<any>(() =>
    import("src/templates/t22/components/pages/PageDateInquiry").then(m => m.PageDateInquiryT22)
);
const PageGuestbookT22 = dynamic<any>(() =>
    import("src/templates/t22/components/pages/PageGuestbook").then(m => m.PageGuestbookT22)
);
const PageImprintT22 = dynamic<any>(() =>
    import("src/templates/t22/components/pages/PageImprint").then(m => m.PageImprintT22)
);
const PageNewsT22 = dynamic<any>(() =>
    import("src/templates/t22/components/pages/PageNews").then(m => m.PageNewsT22)
);
const PageRepairInquiryT22 = dynamic<any>(() =>
    import("src/templates/t22/components/pages/PageRepairInquiry").then(m => m.PageRepairInquiryT22)
);
import { PageT23 } from "src/templates/t23/components/pages/Page";
import { PageBrandT23 } from "src/templates/t23/components/pages/PageBrand";
import { PageBrandOverviewT23 } from "src/templates/t23/components/pages/PageBrandOverview";
import { PageBrandTopicT23 } from "src/templates/t23/components/pages/PageBrandTopic";
import { PageContactT23 } from "src/templates/t23/components/pages/PageContact";
import { PageCourseT23 } from "src/templates/t23/components/pages/PageCourse";
import { PageDateInquiryT23 } from "src/templates/t23/components/pages/PageDateInquiry";
import { PageGuestbookT23 } from "src/templates/t23/components/pages/PageGuestbook";
import { PageImprintT23 } from "src/templates/t23/components/pages/PageImprint";
import { PageNewsT23 } from "src/templates/t23/components/pages/PageNews";
import { PageRepairInquiryT23 } from "src/templates/t23/components/pages/PageRepairInquiry";
import { PageT24 } from "src/templates/t24/components/pages/Page";
import { PageBrandT24 } from "src/templates/t24/components/pages/PageBrand";
import { PageBrandOverviewT24 } from "src/templates/t24/components/pages/PageBrandOverview";
import { PageBrandTopicT24 } from "src/templates/t24/components/pages/PageBrandTopic";
import { PageContactT24 } from "src/templates/t24/components/pages/PageContact";
import { PageCourseT24 } from "src/templates/t24/components/pages/PageCourse";
import { PageDateInquiryT24 } from "src/templates/t24/components/pages/PageDateInquiry";
import { PageGuestbookT24 } from "src/templates/t24/components/pages/PageGuestbook";
import { PageImprintT24 } from "src/templates/t24/components/pages/PageImprint";
import { PageMicroT24 } from "src/templates/t24/components/pages/PageMicro";
import { PageNewsT24 } from "src/templates/t24/components/pages/PageNews";
import { PageRepairInquiryT24 } from "src/templates/t24/components/pages/PageRepairInquiry";
import { PageT25 } from "src/templates/t25/components/pages/Page";
import { PageBrandT25 } from "src/templates/t25/components/pages/PageBrand";
import { PageBrandOverviewT25 } from "src/templates/t25/components/pages/PageBrandOverview";
import { PageBrandTopicT25 } from "src/templates/t25/components/pages/PageBrandTopic";
import { PageContactT25 } from "src/templates/t25/components/pages/PageContact";
import { PageCourseT25 } from "src/templates/t25/components/pages/PageCourse";
import { PageDateInquiryT25 } from "src/templates/t25/components/pages/PageDateInquiry";
import { PageGuestbookT25 } from "src/templates/t25/components/pages/PageGuestbook";
import { PageImprintT25 } from "src/templates/t25/components/pages/PageImprint";
import { PageNewsT25 } from "src/templates/t25/components/pages/PageNews";
import { PageRepairInquiryT25 } from "src/templates/t25/components/pages/PageRepairInquiry";
import { PageSearchT25 } from "src/templates/t25/components/pages/PageSearch";
import { PageT26 } from "src/templates/t26/components/pages/Page";
import { PageBrandT26 } from "src/templates/t26/components/pages/PageBrand";
import { PageBrandOverviewT26 } from "src/templates/t26/components/pages/PageBrandOverview";
import { PageBrandTopicT26 } from "src/templates/t26/components/pages/PageBrandTopic";
import { PageContactT26 } from "src/templates/t26/components/pages/PageContact";
import { PageCourseT26 } from "src/templates/t26/components/pages/PageCourse";
import { PageDateInquiryT26 } from "src/templates/t26/components/pages/PageDateInquiry";
import { PageGuestbookT26 } from "src/templates/t26/components/pages/PageGuestbook";
import { PageImprintT26 } from "src/templates/t26/components/pages/PageImprint";
import { PageNewsT26 } from "src/templates/t26/components/pages/PageNews";
import { PageRepairInquiryT26 } from "src/templates/t26/components/pages/PageRepairInquiry";
import { PageSearchT26 } from "src/templates/t26/components/pages/PageSearch";
import { PageT3 } from "src/templates/t3/components/pages/Page";
import { PageBrandT3 } from "src/templates/t3/components/pages/PageBrand";
import { PageBrandOldT3 } from "src/templates/t3/components/pages/PageBrandOld";
import { PageBrandOverviewT3 } from "src/templates/t3/components/pages/PageBrandOverview";
import { PageContactT3 } from "src/templates/t3/components/pages/PageContact";
import { PageCourseT3 } from "src/templates/t3/components/pages/PageCourse";
import { PageDateInquiryT3 } from "src/templates/t3/components/pages/PageDateInquiry";
import { PageGuestbookT3 } from "src/templates/t3/components/pages/PageGuestbook";
import { PageImprintT3 } from "src/templates/t3/components/pages/PageImprint";
import { PageMicroT3 } from "src/templates/t3/components/pages/PageMicro";
import { PageNewsT3 } from "src/templates/t3/components/pages/PageNews";
import { PageRepairInquiryT3 } from "src/templates/t3/components/pages/PageRepairInquiry";
import { PageT4 } from "src/templates/t4/components/pages/Page";
import { PageBrandT4 } from "src/templates/t4/components/pages/PageBrand";
import { PageBrandOverviewT4 } from "src/templates/t4/components/pages/PageBrandOverview";
import { PageBrandTopicT4 } from "src/templates/t4/components/pages/PageBrandTopic";
import { PageContactT4 } from "src/templates/t4/components/pages/PageContact";
import { PageDateInquiryT4 } from "src/templates/t4/components/pages/PageDateInquiry";
import { PageGuestbookT4 } from "src/templates/t4/components/pages/PageGuestbook";
import { PageImprintT4 } from "src/templates/t4/components/pages/PageImprint";
import { PageMicroT4 } from "src/templates/t4/components/pages/PageMicro";
import { PageNewsT4 } from "src/templates/t4/components/pages/PageNews";
import { PageRepairInquiryT4 } from "src/templates/t4/components/pages/PageRepairInquiry";
import { PageT5 } from "src/templates/t5/components/pages/Page";
import { PageBrandT5 } from "src/templates/t5/components/pages/PageBrand";
import { PageBrandOverviewT5 } from "src/templates/t5/components/pages/PageBrandOverview";
import { PageBrandTopicT5 } from "src/templates/t5/components/pages/PageBrandTopic";
import { PageContactT5 } from "src/templates/t5/components/pages/PageContact";
import { PageDateInquiryT5 } from "src/templates/t5/components/pages/PageDateInquiry";
import { PageGuestbookT5 } from "src/templates/t5/components/pages/PageGuestbook";
import { PageImprintT5 } from "src/templates/t5/components/pages/PageImprint";
import { PageMicroT5 } from "src/templates/t5/components/pages/PageMicro";
import { PageNewsT5 } from "src/templates/t5/components/pages/PageNews";
import { PageRepairInquiryT5 } from "src/templates/t5/components/pages/PageRepairInquiry";
import { PageT6 } from "src/templates/t6/components/pages/Page";
import { PageBrandT6 } from "src/templates/t6/components/pages/PageBrand";
import { PageBrandOverviewT6 } from "src/templates/t6/components/pages/PageBrandOverview";
import { PageBrandTopicT6 } from "src/templates/t6/components/pages/PageBrandTopic";
import { PageContactT6 } from "src/templates/t6/components/pages/PageContact";
import { PageDateInquiryT6 } from "src/templates/t6/components/pages/PageDateInquiry";
import { PageGuestbookT6 } from "src/templates/t6/components/pages/PageGuestbook";
import { PageImprintT6 } from "src/templates/t6/components/pages/PageImprint";
import { PageMicroT6 } from "src/templates/t6/components/pages/PageMicro";
import { PageNewsT6 } from "src/templates/t6/components/pages/PageNews";
import { PageRepairInquiryT6 } from "src/templates/t6/components/pages/PageRepairInquiry";
import { PageT7 } from "src/templates/t7/components/pages/Page";
import { PageBrandT7 } from "src/templates/t7/components/pages/PageBrand";
import { PageBrandOverviewT7 } from "src/templates/t7/components/pages/PageBrandOverview";
import { PageBrandTopicT7 } from "src/templates/t7/components/pages/PageBrandTopic";
import { PageContactT7 } from "src/templates/t7/components/pages/PageContact";
import { PageDateInquiryT7 } from "src/templates/t7/components/pages/PageDateInquiry";
import { PageGuestbookT7 } from "src/templates/t7/components/pages/PageGuestbook";
import { PageImprintT7 } from "src/templates/t7/components/pages/PageImprint";
import { PageMicroT7 } from "src/templates/t7/components/pages/PageMicro";
import { PageNewsT7 } from "src/templates/t7/components/pages/PageNews";
import { PageRepairInquiryT7 } from "src/templates/t7/components/pages/PageRepairInquiry";
import { PageT8 } from "src/templates/t8/components/pages/Page";
import { PageBrandT8 } from "src/templates/t8/components/pages/PageBrand";
import { PageBrandOverviewT8 } from "src/templates/t8/components/pages/PageBrandOverview";
import { PageContactT8 } from "src/templates/t8/components/pages/PageContact";
import { PageDateInquiryT8 } from "src/templates/t8/components/pages/PageDateInquiry";
import { PageGuestbookT8 } from "src/templates/t8/components/pages/PageGuestbook";
import { PageImprintT8 } from "src/templates/t8/components/pages/PageImprint";
import { PageNewsT8 } from "src/templates/t8/components/pages/PageNews";
import { PageRepairInquiryT8 } from "src/templates/t8/components/pages/PageRepairInquiry";
import { PageT9 } from "src/templates/t9/components/pages/Page";
import { PageBrandT9 } from "src/templates/t9/components/pages/PageBrand";
import { PageBrandOverviewT9 } from "src/templates/t9/components/pages/PageBrandOverview";
import { PageContactT9 } from "src/templates/t9/components/pages/PageContact";
import { PageDateInquiryT9 } from "src/templates/t9/components/pages/PageDateInquiry";
import { PageGuestbookT9 } from "src/templates/t9/components/pages/PageGuestbook";
import { PageImprintT9 } from "src/templates/t9/components/pages/PageImprint";
import { PageNewsT9 } from "src/templates/t9/components/pages/PageNews";
import { PageRepairInquiryT9 } from "src/templates/t9/components/pages/PageRepairInquiry";
import { getPageType } from "../common/utils/getPageType";
import { PageMicroT10 } from "../templates/t10/components/pages/PageMicro";
import { PageCourseT11 } from "../templates/t11/components/pages/PageCourse";
import { PageMicroT11 } from "../templates/t11/components/pages/PageMicro";
import { PageCourseT12 } from "../templates/t12/components/pages/PageCourse";
import { PageMicroT12 } from "../templates/t12/components/pages/PageMicro";
import { PageMicroT13 } from "../templates/t13/components/pages/PageMicro";
import { PageMicroT14 } from "../templates/t14/components/pages/PageMicro";
import { PageCourseT15 } from "../templates/t15/components/pages/PageCourse";
import { PageMicroT15 } from "../templates/t15/components/pages/PageMicro";
import { PageCourseT17 } from "../templates/t17/components/pages/PageCourse";
import { PageMicroT17 } from "../templates/t17/components/pages/PageMicro";
import { PageCourseT21 } from "../templates/t21/components/pages/PageCourse";
import { PageMicroT21 } from "../templates/t21/components/pages/PageMicro";
import { PageMicroT23 } from "../templates/t23/components/pages/PageMicro";
import { PageSearchT23 } from "../templates/t23/components/pages/PageSearch";
import { PageSearchT24 } from "../templates/t24/components/pages/PageSearch";
import { PageMicroT25 } from "../templates/t25/components/pages/PageMicro";
import { PageMicroT26 } from "../templates/t26/components/pages/PageMicro";
import { PageMicroT0 } from "../templates/t0/components/pages/PageMicro";
import { PageBrandT0 } from "../templates/t0/components/pages/PageBrand";
import { PageBrandOverviewT0 } from "../templates/t0/components/pages/PageBrandOverview";
import { PageBrandTopicT0 } from "../templates/t0/components/pages/PageBrandTopic";
import { PageBrandTopicT3 } from "../templates/t3/components/pages/PageBrandTopic";
import { PageSearchT5 } from "../templates/t5/components/pages/PageSearch";
import { PageSearchT7 } from "../templates/t7/components/pages/PageSearch";
import { PageCourseT8 } from "../templates/t8/components/pages/PageCourse";
import { PageMicroT8 } from "../templates/t8/components/pages/PageMicro";
import { PageCourseT9 } from "../templates/t9/components/pages/PageCourse";
import { PageMicroT9 } from "../templates/t9/components/pages/PageMicro";
import { PageBrandOldT1 } from "../templates/t1/components/pages/PageBrandOld";
import { PageBrandOldT4 } from "../templates/t4/components/pages/PageBrandOld";
import { PageBrandOldT6 } from "../templates/t6/components/pages/PageBrandOld";
import { PageBrandOldT5 } from "../templates/t5/components/pages/PageBrandOld";
import { PageBrandOldT7 } from "../templates/t7/components/pages/PageBrandOld";
import { PageBrandOldT11 } from "../templates/t11/components/pages/PageBrandOld";
import { PageBrandOverviewOldT1 } from "../templates/t1/components/pages/PageBrandOverviewOld";
import { PageBrandOverviewOldT3 } from "../templates/t3/components/pages/PageBrandOverviewOld";
import { PageBrandOverviewOldT4 } from "../templates/t4/components/pages/PageBrandOverviewOld";
import { PageBrandOverviewOldT6 } from "../templates/t6/components/pages/PageBrandOverviewOld";
import { PageBrandOverviewOldT5 } from "../templates/t5/components/pages/PageBrandOverviewOld";
import { PageBrandOverviewOldT7 } from "../templates/t7/components/pages/PageBrandOverviewOld";
import { PageBrandOverviewOldT11 } from "../templates/t11/components/pages/PageBrandOverviewOld";
import { PageBrandMicroT3 } from "../templates/t3/components/pages/PageBrandMicro";
import { PageBrandMicroT1 } from "src/templates/t1/components/pages/PageBrandMicro";
import { PageBrandMicroT4 } from "src/templates/t4/components/pages/PageBrandMicro";
import { PageBrandMicroT6 } from "src/templates/t6/components/pages/PageBrandMicro";
import { PageBrandMicroT5 } from "src/templates/t5/components/pages/PageBrandMicro";
import { PageBrandMicroT7 } from "src/templates/t7/components/pages/PageBrandMicro";
import { PageBrandMicroT11 } from "src/templates/t11/components/pages/PageBrandMicro";
const PageT27 = dynamic<any>(() => import("../templates/t27/components/pages/Page").then((m) => m.PageT27));
const PageBrandT27 = dynamic<any>(() =>
    import("../templates/t27/components/pages/PageBrand").then((m) => m.PageBrandT27)
);
const PageBrandOverviewT27 = dynamic<any>(() =>
    import("../templates/t27/components/pages/PageBrandOverview").then((m) => m.PageBrandOverviewT27)
);
const PageBrandTopicT27 = dynamic<any>(() =>
    import("../templates/t27/components/pages/PageBrandTopic").then((m) => m.PageBrandTopicT27)
);
const PageContactT27 = dynamic<any>(() =>
    import("../templates/t27/components/pages/PageContact").then((m) => m.PageContactT27)
);
const PageCourseT27 = dynamic<any>(() =>
    import("../templates/t27/components/pages/PageCourse").then((m) => m.PageCourseT27)
);
const PageDateInquiryT27 = dynamic<any>(() =>
    import("../templates/t27/components/pages/PageDateInquiry").then((m) => m.PageDateInquiryT27)
);
const PageImprintT27 = dynamic<any>(() =>
    import("../templates/t27/components/pages/PageImprint").then((m) => m.PageImprintT27)
);
const PageMicroT27 = dynamic<any>(() =>
    import("../templates/t27/components/pages/PageMicro").then((m) => m.PageMicroT27)
);
const PageNewsT27 = dynamic<any>(() => import("../templates/t27/components/pages/PageNews").then((m) => m.PageNewsT27));
const PageRepairInquiryT27 = dynamic<any>(() =>
    import("../templates/t27/components/pages/PageRepairInquiry").then((m) => m.PageRepairInquiryT27)
);
const PageT28 = dynamic<any>(() => import("../templates/t28/components/pages/Page").then((m) => m.PageT28));
const PageBrandT28 = dynamic<any>(() =>
    import("../templates/t28/components/pages/PageBrand").then((m) => m.PageBrandT28)
);
const PageBrandOverviewT28 = dynamic<any>(() =>
    import("../templates/t28/components/pages/PageBrandOverview").then((m) => m.PageBrandOverviewT28)
);
const PageBrandTopicT28 = dynamic<any>(() =>
    import("../templates/t28/components/pages/PageBrandTopic").then((m) => m.PageBrandTopicT28)
);
const PageContactT28 = dynamic<any>(() =>
    import("../templates/t28/components/pages/PageContact").then((m) => m.PageContactT28)
);
const PageCourseT28 = dynamic<any>(() =>
    import("../templates/t28/components/pages/PageCourse").then((m) => m.PageCourseT28)
);
const PageDateInquiryT28 = dynamic<any>(() =>
    import("../templates/t28/components/pages/PageDateInquiry").then((m) => m.PageDateInquiryT28)
);
const PageImprintT28 = dynamic<any>(() =>
    import("../templates/t28/components/pages/PageImprint").then((m) => m.PageImprintT28)
);
const PageMicroT28 = dynamic<any>(() =>
    import("../templates/t28/components/pages/PageMicro").then((m) => m.PageMicroT28)
);
const PageNewsT28 = dynamic<any>(() => import("../templates/t28/components/pages/PageNews").then((m) => m.PageNewsT28));
const PageRepairInquiryT28 = dynamic<any>(() =>
    import("../templates/t28/components/pages/PageRepairInquiry").then((m) => m.PageRepairInquiryT28)
);
const PageT29 = dynamic<any>(() => import("../templates/t29/components/pages/Page").then((m) => m.PageT29));
const PageBrandT29 = dynamic<any>(() =>
    import("../templates/t29/components/pages/PageBrand").then((m) => m.PageBrandT29)
);
const PageBrandOverviewT29 = dynamic<any>(() =>
    import("../templates/t29/components/pages/PageBrandOverview").then((m) => m.PageBrandOverviewT29)
);
const PageBrandTopicT29 = dynamic<any>(() =>
    import("../templates/t29/components/pages/PageBrandTopic").then((m) => m.PageBrandTopicT29)
);
const PageContactT29 = dynamic<any>(() =>
    import("../templates/t29/components/pages/PageContact").then((m) => m.PageContactT29)
);
const PageCourseT29 = dynamic<any>(() =>
    import("../templates/t29/components/pages/PageCourse").then((m) => m.PageCourseT29)
);
const PageDateInquiryT29 = dynamic<any>(() =>
    import("../templates/t29/components/pages/PageDateInquiry").then((m) => m.PageDateInquiryT29)
);
const PageImprintT29 = dynamic<any>(() =>
    import("../templates/t29/components/pages/PageImprint").then((m) => m.PageImprintT29)
);
const PageMicroT29 = dynamic<any>(() =>
    import("../templates/t29/components/pages/PageMicro").then((m) => m.PageMicroT29)
);
const PageNewsT29 = dynamic<any>(() => import("../templates/t29/components/pages/PageNews").then((m) => m.PageNewsT29));
const PageRepairInquiryT29 = dynamic<any>(() =>
    import("../templates/t29/components/pages/PageRepairInquiry").then((m) => m.PageRepairInquiryT29)
);

const SlugPage = ({ template, useMarkenwelt, pageIsBrandNavSubpage, ...props }): JSX.Element => {
    switch (template) {
        case "t0":
            if (
                props?.PageBrandTopic?.slug &&
                `${props?.PageBrand?.slug}` === `${props?.slug}` &&
                `${props?.PageBrandTopic?.slug}` === `${props?.slugs[1]}`
            ) {
                return <PageBrandTopicT0 />;
            }

            switch (`${props?.slug}`) {
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT0 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT0 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT0 />;                    
                default:
                    return <Custom404 />;
            }
        case "t27":
            if (
                props?.PageBrandTopic?.slug &&
                `${props?.PageBrand?.slug}` === `${props?.slug}` &&
                `${props?.PageBrandTopic?.slug}` === `${props?.slugs[1]}`
            ) {
                return <PageBrandTopicT27 />;
            }

            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT27 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT27 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT27 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT27 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT27 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT27 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT27 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT27 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT27 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT27 />;

                default:
                    return <Custom404 />;
            }
        case "t28":
            if (
                props?.PageBrandTopic?.slug &&
                `${props?.PageBrand?.slug}` === `${props?.slug}` &&
                `${props?.PageBrandTopic?.slug}` === `${props?.slugs[1]}`
            ) {
                return <PageBrandTopicT28 />;
            }

            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT28 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT28 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT28 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT28 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT28 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT28 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT28 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT28 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT28 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT28 />;

                default:
                    return <Custom404 />;
            }
        case "t29":
            if (
                props?.PageBrandTopic?.slug &&
                `${props?.PageBrand?.slug}` === `${props?.slug}` &&
                `${props?.PageBrandTopic?.slug}` === `${props?.slugs[1]}`
            ) {
                return <PageBrandTopicT29 />;
            }

            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT29 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT29 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT29 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT29 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT29 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT29 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT29 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT29 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT29 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT29 />;

                default:
                    return <Custom404 />;
            }

        case "t25":
            switch (true) {
                case props?.PageBrandTopic?.slug &&
                    `${props?.PageBrand?.slug}` === `${props?.slug}` &&
                    `${props?.PageBrandTopic?.slug}` === `${props?.slugs[1]}`:
                    console.log("PageBrandTopicT25");
                    return <PageBrandTopicT25 />;
            }

            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT25 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT25 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT25 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT25 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT25 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT25 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT25 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT25 />;
                case `${props?.PageContact?.slug}`:
                    return props?.dealer?.dachseite ? <PageSearchT25 /> : <PageContactT25 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT25 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT25 />;
                default:
                    return <Custom404 />;
            }

        case "t26":
            switch (true) {
                case props?.PageBrandTopic?.slug &&
                    `${props?.PageBrand?.slug}` === `${props?.slug}` &&
                    `${props?.PageBrandTopic?.slug}` === `${props?.slugs[1]}`:
                    console.log("PageBrandTopicT26");
                    return <PageBrandTopicT26 />;
            }

            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT26 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT26 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT26 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT26 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT26 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT26 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT26 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT26 />;
                case `${props?.PageContact?.slug}`:
                    return props?.dealer?.dachseite ? <PageSearchT26 /> : <PageContactT26 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT26 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT26 />;
                default:
                    return <Custom404 />;
            }

        case "t23":
            switch (true) {
                case props?.PageBrandTopic?.slug &&
                    `${props?.PageBrand?.slug}` === `${props?.slug}` &&
                    `${props?.PageBrandTopic?.slug}` === `${props?.slugs[1]}`:
                    console.log("PageBrandTopicT23");
                    return <PageBrandTopicT23 />;
            }

            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT23 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT23 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT23 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT23 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT23 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT23 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT23 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT23 />;
                case `${props?.PageContact?.slug}`:
                    return props?.dealer?.dachseite ? <PageSearchT23 /> : <PageContactT23 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT23 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT23 />;
                default:
                    return <Custom404 />;
            }

        case "t24":
            switch (true) {
                case props?.PageBrandTopic?.slug &&
                    `${props?.PageBrand?.slug}` === `${props?.slug}` &&
                    `${props?.PageBrandTopic?.slug}` === `${props?.slugs[1]}`:
                    console.log("PageBrandTopicT24");
                    return <PageBrandTopicT24 />;
            }

            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT24 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT24 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT24 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT24 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT24 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT24 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT24 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT24 />;
                case `${props?.PageContact?.slug}`:
                    return props?.dealer?.dachseite ? <PageSearchT24 /> : <PageContactT24 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT24 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT24 />;
                default:
                    return <Custom404 />;
            }

        case "t22":
            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT22 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT22 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT22 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT22 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT22 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT22 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT22 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT22 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT22 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT22 />;
                default:
                    return <Custom404 />;
            }

        case "t21":
            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT21 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT21 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT21 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT21 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT21 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT21 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT21 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT21 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT21 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT21 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT21 />;
                default:
                    return <Custom404 />;
            }

        case "t20":
            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT20 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT20 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT20 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT20 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT20 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT20 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT20 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT20 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT20 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT20 />;
                default:
                    return <Custom404 />;
            }

        case "t19":
            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT19 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT19 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT19 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT19 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT19 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT19 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT19 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT19 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT19 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT19 />;
                default:
                    return <Custom404 />;
            }

        case "t18":
            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT18 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT18 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT18 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT18 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT18 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT18 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT18 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT18 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT18 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT18 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT18 />;
                default:
                    return <Custom404 />;
            }

        case "t17":
            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT17 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT17 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT17 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT17 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT17 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT17 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT17 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT17 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT17 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT17 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT17 />;
                default:
                    return <Custom404 />;
            }

        case "t16":
            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT16 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT16 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT16 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT16 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT16 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT16 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT16 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT16 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT16 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT16 />;
                default:
                    return <Custom404 />;
            }

        case "t14":
            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT14 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT14 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT14 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT14 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT14 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT14 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT14 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT14 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT14 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT14 />;
                default:
                    return <Custom404 />;
            }

        case "t13":
            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT13 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT13 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT13 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT13 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT13 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT13 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT13 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT13 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT13 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT13 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT13 />;
                default:
                    return <Custom404 />;
            }

        case "t12":
            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT12 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT12 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT12 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT12 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT12 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT12 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT12 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT12 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT12 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT12 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT12 />;
                default:
                    return <Custom404 />;
            }
        case "t15":
            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT15 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT15 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT15 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT15 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT15 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT15 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT15 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT15 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT15 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT15 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT15 />;
                default:
                    return <Custom404 />;
            }

        case "t11":
            switch (true) {
                case props?.PageBrandTopic?.slug &&
                    `${props?.PageBrand?.slug}` === `${props?.slug}` &&
                    `${props?.PageBrandTopic?.slug}` === `${props?.slugs[1]}`:
                    console.log("PageBrandTopicT11");
                    return <PageBrandTopicT11 />;
            }

            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT11 />;
                case `${props?.PageBrandOverview?.slug}`:
                    if (!useMarkenwelt) {
                        return <PageBrandOverviewOldT11 />;
                    }
                    return <PageBrandOverviewT11 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT11 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT11 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT11 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT11 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT11 />;
                case `${props?.PageBrand?.slug}`:
                    if (!useMarkenwelt) {
                        return <PageBrandOldT11 />;
                    }
                    return props?.PageBrand?.__extTypename == "Old" ? <PageBrandOldT11 /> : <PageBrandT11 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT11 />;
                case `${props?.PageMicro?.slug}`:
                    if (useMarkenwelt && pageIsBrandNavSubpage) {
                        return <PageBrandMicroT11 />;
                    }
                    return <PageMicroT11 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT11 />;
                default:
                    return <Custom404 />;
            }

        case "t10":
            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT10 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT10 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT10 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT10 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT10 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT10 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT10 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT10 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT10 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT10 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT10 />;
                default:
                    return <Custom404 />;
            }

        case "t9":
            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT9 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT9 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT9 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT9 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT9 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT9 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT9 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT9 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT9 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT9 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT9 />;
                default:
                    return <Custom404 />;
            }
        case "t8":
            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT8 />;
                case `${props?.PageMicro?.slug}`:
                    return <PageMicroT8 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT8 />;
                case `${props?.PageBrandOverview?.slug}`:
                    return <PageBrandOverviewT8 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT8 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT8 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT8 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT8 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT8 />;
                case `${props?.PageBrand?.slug}`:
                    return <PageBrandT8 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT8 />;
                default:
                    return <Custom404 />;
            }
        case "t7":
            switch (true) {
                case props?.PageBrandTopic?.slug &&
                    `${props?.PageBrand?.slug}` === `${props?.slug}` &&
                    `${props?.PageBrandTopic?.slug}` === `${props?.slugs[1]}`:
                    console.log("PageBrandTopicT7");
                    return <PageBrandTopicT7 />;
            }

            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT7 />;
                case `${props?.PageBrandOverview?.slug}`:
                    if (!useMarkenwelt) {
                        return <PageBrandOverviewOldT7 />;
                    }
                    return <PageBrandOverviewT7 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT7 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT7 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT7 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT7 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT7 />;
                case `${props?.PageBrand?.slug}`:
                    if (!useMarkenwelt) {
                        return <PageBrandOldT7 />;
                    }
                    return props?.PageBrand?.__extTypename == "Old" ? <PageBrandOldT7 /> : <PageBrandT7 />;
                case `${props?.PageContact?.slug}`:
                    return props?.dealer?.dachseite ? <PageSearchT7 /> : <PageContactT7 />;
                // eslint-disable-next-line no-duplicate-case
                case `${props?.PageContact?.slug}`:
                    return <PageContactT7 />;
                case `${props?.PageMicro?.slug}`:
                    if (useMarkenwelt && pageIsBrandNavSubpage) {
                        return <PageBrandMicroT7 />;
                    }
                    return <PageMicroT7 />;
                default:
                    return <Custom404 />;
            }
        case "t5":
            switch (true) {
                case props?.PageBrandTopic?.slug &&
                    `${props?.PageBrand?.slug}` === `${props?.slug}` &&
                    `${props?.PageBrandTopic?.slug}` === `${props?.slugs[1]}`:
                    return <PageBrandTopicT5 />;
            }

            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT5 />;
                case `${props?.PageBrandOverview?.slug}`:
                    if (!useMarkenwelt) {
                        return <PageBrandOverviewOldT5 />;
                    }
                    return <PageBrandOverviewT5 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT5 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT5 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT5 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT5 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT5 />;
                case `${props?.PageBrand?.slug}`:
                    if (!useMarkenwelt) {
                        return <PageBrandOldT5 />;
                    }
                    return props?.PageBrand?.__extTypename == "Old" ? <PageBrandOldT5 /> : <PageBrandT5 />;
                case `${props?.PageContact?.slug}`:
                    return props?.dealer?.dachseite ? <PageSearchT5 /> : <PageContactT5 />;
                case `${props?.PageMicro?.slug}`:
                    if (useMarkenwelt && pageIsBrandNavSubpage) {
                        return <PageBrandMicroT5 />;
                    }
                    return <PageMicroT5 />;
                default:
                    return <Custom404 />;
            }
        case "t6":
            switch (true) {
                case props?.PageBrandTopic?.slug &&
                    `${props?.PageBrand?.slug}` === `${props?.slug}` &&
                    `${props?.PageBrandTopic?.slug}` === `${props?.slugs[1]}`:
                    return <PageBrandTopicT6 />;
            }

            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT6 />;
                case `${props?.PageBrandOverview?.slug}`:
                    if (!useMarkenwelt) {
                        return <PageBrandOverviewOldT6 />;
                    }
                    return <PageBrandOverviewT6 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT6 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT6 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT6 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT6 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT6 />;
                case `${props?.PageBrand?.slug}`:
                    if (!useMarkenwelt) {
                        return <PageBrandOldT6 />;
                    }
                    return props?.PageBrand?.__extTypename == "Old" ? <PageBrandOldT6 /> : <PageBrandT6 />;

                case `${props?.PageContact?.slug}`:
                    return <PageContactT6 />;
                case `${props?.PageMicro?.slug}`:
                    if (useMarkenwelt && pageIsBrandNavSubpage) {
                        return <PageBrandMicroT6 />;
                    }
                    return <PageMicroT6 />;
                default:
                    return <Custom404 />;
            }
        case "t4":
            switch (true) {
                case props?.PageBrandTopic?.slug &&
                    `${props?.PageBrand?.slug}` === `${props?.slug}` &&
                    `${props?.PageBrandTopic?.slug}` === `${props?.slugs[1]}`:
                    return <PageBrandTopicT4 />;
            }

            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT4 />;
                case `${props?.PageBrandOverview?.slug}`:
                    if (!useMarkenwelt) {
                        return <PageBrandOverviewOldT4 />;
                    }
                    return <PageBrandOverviewT4 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT4 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT4 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT4 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT4 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT4 />;
                case `${props?.PageBrand?.slug}`:
                    if (!useMarkenwelt) {
                        return <PageBrandOldT4 />;
                    }
                    return props?.PageBrand?.__extTypename == "Old" ? <PageBrandOldT4 /> : <PageBrandT4 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT4 />;
                case `${props?.PageMicro?.slug}`:
                    if (useMarkenwelt && pageIsBrandNavSubpage) {
                        return <PageBrandMicroT4 />;
                    }
                    return <PageMicroT4 />;
                default:
                    return <Custom404 />;
            }
        case "t3":
            switch (true) {
                case props?.PageBrandTopic?.slug &&
                    `${props?.PageBrand?.slug}` === `${props?.slug}` &&
                    `${props?.PageBrandTopic?.slug}` === `${props?.slugs[1]}`:
                    return <PageBrandTopicT3 />;
            }

            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT3 />;
                case `${props?.PageBrandOverview?.slug}`:
                    if (!useMarkenwelt) {
                        return <PageBrandOverviewOldT3 />;
                    }
                    return <PageBrandOverviewT3 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT3 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT3 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT3 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT3 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT3 />;
                case `${props?.PageBrand?.slug}`:
                    if (!useMarkenwelt) {
                        return <PageBrandOldT3 />;
                    }
                    return props?.PageBrand?.__extTypename == "Old" ? <PageBrandOldT3 /> : <PageBrandT3 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT3 />;
                case `${props?.PageMicro?.slug}`:
                    if (useMarkenwelt && pageIsBrandNavSubpage) {
                        return <PageBrandMicroT3 />;
                    }
                    return <PageMicroT3 />;
                case `${props?.PageCourse?.slug}`:
                    return <PageCourseT3 />;
                default:
                    return <Custom404 />;
            }
        case "t2":
        case "t1":
            switch (true) {
                case props?.PageBrandTopic?.slug &&
                    `${props?.PageBrand?.slug}` === `${props?.slug}` &&
                    `${props?.PageBrandTopic?.slug}` === `${props?.slugs[1]}`:
                    return <PageBrandTopicT1 />;
            }

            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                    return <PageT1 />;
                case `${props?.PageBrandOverview?.slug}`:
                    if (!useMarkenwelt) {
                        return <PageBrandOverviewOldT1 />;
                    }
                    return <PageBrandOverviewT1 />;
                case `${props?.PageNews?.slug}`:
                    return <PageNewsT1 />;
                case `${props?.PageGuestbook?.slug}`:
                    return <PageGuestbookT1 />;
                case `${props?.PageDateInquiry?.slug}`:
                    return <PageDateInquiryT1 />;
                case `${props?.PageRepairInquiry?.slug}`:
                    return <PageRepairInquiryT1 />;
                case `${props?.PageImprint?.slug}`:
                    return <PageImprintT1 />;
                case `${props?.PageBrand?.slug}`:
                    if (!useMarkenwelt) {
                        return <PageBrandOldT1 />;
                    }
                    return props?.PageBrand?.__extTypename == "Old" ? <PageBrandOldT1 /> : <PageBrandT1 />;
                case `${props?.PageContact?.slug}`:
                    return <PageContactT1 />;
                case `${props?.PageMicro?.slug}`:
                    if (useMarkenwelt && pageIsBrandNavSubpage) {
                        return <PageBrandMicroT1 />;
                    }
                    return <PageMicroT1 />;
                default:
                    return <Custom404 />;
            }
        default:
            return <Custom404 />;
    }
};

const Slug = (props): ReactElement => {
    const template = props.Settings?.template || DEFAULT_TEMPLATE;
    const pageType = getPageType(props);
    const pageIsBrandNavSubpage = props?.pageIsBrandNavSubpage;
    // const useMarkenwelt = props?.CompanyInformationPrivate?.useMarkenwelt?.value === true || props?.host?.includes(".dev.gedk.");
    const useMarkenwelt = true;
    // const useMarkenwelt = props?.CompanyInformationPrivate?.useMarkenwelt?.value === true;
    let backgroundColor = props?.Settings?.themeBackgroundColor;
    let primaryColor = props?.Settings?.themeColorPrimary;
    let brandAreaMaxWidth = 1366;
    let applyBodyBackground = true;

    switch (template) {
        case "t1":
            !backgroundColor && (backgroundColor = "#ffca00");
            !primaryColor && (primaryColor = "#d30032");
            brandAreaMaxWidth = 955;
            applyBodyBackground = false;
            break;
        case "t2":
            !backgroundColor && (backgroundColor = "#B7B7B7");
            !primaryColor && (primaryColor = "#d30032");
            brandAreaMaxWidth = 955;
            applyBodyBackground = false;
            break;
        case "t5":
            !backgroundColor && (backgroundColor = "#ffffff");
            !primaryColor && (primaryColor = "#008bc5");
            brandAreaMaxWidth = 1600;
            applyBodyBackground = false;
            break;
        case "t6":
            !backgroundColor && (backgroundColor = "#ffffff");
            !primaryColor && (primaryColor = "#009FE3");
            brandAreaMaxWidth = 1600;
            applyBodyBackground = false;
            break;
        default:
            !backgroundColor && (backgroundColor = "#ffffff");
            !primaryColor && (primaryColor = "#FF0001");
    }

    if (typeof window !== "undefined") {
        console.log(" props", { props });
        console.log(
            `useMarkenwelt=${useMarkenwelt} template=${props.Settings?.template} pageType=${pageType} backgroundColor=${backgroundColor} primaryColor=${primaryColor} %cbackgroundColor %cprimaryColor`,
            `color: ${backgroundColor}`,
            `color: ${primaryColor}`
        );
    }

    return (
        <Context.Provider value={props as any}>
            <CustomHtmlHandler {...props} />
            {((pageIsBrandNavSubpage && !!props.PageMicro) ||
                (pageType === "PageBrand" && props?.PageBrand?.__extTypename != "Old") ||
                pageType === "PageBrandOverview") &&
            useMarkenwelt ? (
                <BrandAreaGlobalStyle
                    applyBodyBackground={applyBodyBackground}
                    brandAreaMaxWidth={brandAreaMaxWidth}
                    backgroundColor={backgroundColor}
                    primaryColor={primaryColor}
                />
            ) : null}
            <SlugPage
                template={template}
                pageIsBrandNavSubpage={pageIsBrandNavSubpage}
                useMarkenwelt={useMarkenwelt}
                {...props}
            />
        </Context.Provider>
    );
};

export const getServerSideProps = getInitialPropsSlug;

export default Slug;
